<div [class]="allowHoverAction && filePreviewType != FileTypes.Video ? 'frame-div' : 'frame-div disable-hover'" fxLayout="row" fxLayout="center center" [ngSwitch]="filePreviewType">
    <iframe *ngSwitchCase="FileTypes.Unknown" [src]="filePath | safe: 'resourceUrl'"></iframe>
    <iframe *ngSwitchCase="FileTypes.PDF" [src]="filePath | safe: 'resourceUrl'"></iframe>
    <img *ngSwitchCase="FileTypes.Image" class="mat-elevation-z5" [src]="filePath | safe: 'resourceUrl'" onerror="this.src = 'assets/images/image_not_found.png'">
    <video *ngSwitchCase="FileTypes.Video" class="video-player" controls onloadedmetadata="this.muted = true; this.volume = 0.1;" #videoPlayer>
        <source [src]="filePath | safe: 'resourceUrl'" [type]="fileType">
        Your browser does not support the video tag.
    </video>
    <div *ngIf="filePreviewType != FileTypes.Video" class="buttons" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
        <a mat-button color="primary" [href]="filePath" target="_blank" rel="noopener">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="icon-small">launch</mat-icon>
                <label>Visualizar arquivo</label>
            </div>
        </a>
    </div>
</div>