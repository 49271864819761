import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post } from 'src/app/models/post';
import { Attachment } from 'src/app/view-models/attachment';

@Component({
    selector: 'app-post-card',
    templateUrl: './post-card.component.html',
    styleUrls: ['./post-card.component.scss']
})

export class PostCardComponent {
    @Input()
    get post(): Post { return this._post; }
    set post(post: Post) {
        this._post = post;
    }
    private _post: Post;

    @Input()
    get allowEditingAnyPost(): boolean { return this._allowEditingAnyPost; }
    set allowEditingAnyPost(allowEditingAnyPost: boolean) {
        this._allowEditingAnyPost = allowEditingAnyPost;
    }
    private _allowEditingAnyPost = true;

    @Input()
    get allowPostManagement(): boolean { return this._allowPostManagement; }
    set allowPostManagement(allowPostManagement: boolean) {
        this._allowPostManagement = allowPostManagement;
    }
    private _allowPostManagement = true;

    @Output() onEditRequest: EventEmitter<Post> = new EventEmitter<Post>();

    editPost() {
        this.onEditRequest.emit(this._post);
    }

    @Output() onDeleteRequest: EventEmitter<Post> = new EventEmitter<Post>();

    deletePost() {
        this.onDeleteRequest.emit(this._post);
    }

    @Output() onPostRate: EventEmitter<Post> = new EventEmitter<Post>();

    ratePost() {
        this.onPostRate.emit(this._post);
    }

    @Output() onVisibilityUpdate: EventEmitter<{ post: Post, ev: any }> = new EventEmitter<{ post: Post, ev: any }>();

    updateVisibility(ev) {
        this.onVisibilityUpdate.emit({ post: this._post, ev: ev });
    }

    selectCarrouselAttachment(attachment: Attachment) {
        this._post.selectedAttachmentIndex = this._post.attachments.indexOf(attachment);
    }

    getPostDescription(post: Post) {
        return post.description?.replace(/\n/g, '<br>');
    }

    isFileImage(fileType: string) {
        switch (fileType) {
            case 'image/png':
                return true;
            case 'image/jpeg':
                return true;
            case 'image/jpg':
                return true;
            default:
                return false;
        }
    }

    isFileVideo(fileType: string) {
        switch (fileType) {
            case 'video/mp4':
                return true;
            default:
                return false;
        }
    }
}
